import formatDistance from './_lib/formatDistance/index.js';
import formatLong from './_lib/formatLong/index.js';
import formatRelative from './_lib/formatRelative/index.js';
import localize from './_lib/localize/index.js';
import match from './_lib/match/index.js';
/**
 * @type {Locale}
 * @category Locales
 * @summary Portuguese locale (Brazil).
 * @language Portuguese
 * @iso-639-2 por
 * @author Lucas Duailibe [@duailibe]{@link https://github.com/duailibe}
 * @author Yago Carballo [@yagocarballo]{@link https://github.com/YagoCarballo}
 */

var locale = {
  code: 'pt-BR',
  formatDistance: formatDistance,
  formatLong: formatLong,
  formatRelative: formatRelative,
  localize: localize,
  match: match,
  options: {
    weekStartsOn: 0
    /* Sunday */
    ,
    firstWeekContainsDate: 1
  }
};
export default locale;